// Here you can add other styles

.carousel-container {
  width: 95vw;
  justify-content: space-between;
  max-width: 1900px;
}

.react-multiple-carousel__arrow {
  background: rgba(0, 0, 0, 0.9) !important;
  margin-bottom: 110px !important;
}

.react-multi-carousel-item {
  margin-bottom: 2px !important;
}

.form-control-error-border {
  border: 1px solid var(--cui-form-invalid-border-color);
}

@include media-breakpoint-up(sm) {
  .carousel-container {
    display: none;
  }
}
